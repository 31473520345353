import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/PageHeader'
import ProjectGrid from '../components/ProjectGrid'
import Layout from '../components/Layout'

/**
 * Filter projects by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {projects} object
 */
export const byDate = (projects) => {
  const now = Date.now()
  return projects.filter((project) => Date.parse(project.date) <= now)
}

/**
 * filter projects by category.
 *
 * @param {projects} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (projects, title, contentType) => {
  const isCategory = contentType === 'projectCategories'
  const byCategory = (project) =>
    project.categories &&
    project.categories.filter((cat) => cat.category === title).length
  return isCategory ? projects.filter(byCategory) : projects
}

// Export Template for use in CMS preview
export const ProjectIndexTemplate = ({
  title,
  subtitle,
  featuredImage,
  projects = [],
  projectCategories = [],
  enableSearch = true,
  contentType
}) => (
  <Location>
    {({ location }) => {
      let filteredProjects =
        projects && !!projects.length
          ? byCategory(byDate(projects), title, contentType)
          : []

      let queryObj = location.search.replace('?', '')
      queryObj = qs.parse(queryObj)

      if (enableSearch && queryObj.s) {
        const searchTerm = queryObj.s.toLowerCase()
        filteredProjects = filteredProjects.filter((project) =>
          project.frontmatter.title.toLowerCase().includes(searchTerm)
        )
      }

      return (
        <main className="main-body">
          <section className="section">
            <div className="container">
              <PageHeader title={title} subtitle={subtitle} />
            </div>
          </section>

          {!!projects.length && (
            <section className="section">
              <div className="container">
                <ProjectGrid projects={filteredProjects} />
              </div>
            </section>
          )}
        </main>
      )
    }}
  </Location>
)

// Export Default ProjectIndex for front-end
const ProjectIndex = ({ data: { page, projects, projectCategories } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ProjectIndexTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      projects={projects.edges.map((project) => ({
        ...project.node,
        ...project.node.frontmatter,
        ...project.node.fields
      }))}
      projectCategories={projectCategories.edges.map((project) => ({
        ...project.node,
        ...project.node.frontmatter,
        ...project.node.fields
      }))}
    />
  </Layout>
)

export default ProjectIndex

export const pageQuery = graphql`
  ## Query for ProjectIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query ProjectIndex($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
      }
    }

    projects: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "projects" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            date
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
    projectCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "projectCategories" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
