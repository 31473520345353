import React from 'react'
import { Link } from 'gatsby'
import ProjectCard from './ProjectCard'
import { ArrowRight } from 'react-feather'
import './ProjectGrid.css'

class ProjectGrid extends React.Component {
  static defaultProps = {
    projects: [],
    title: '',
    limit: 12,
    minimalDesign: false,
    showLoadMore: true,
    loadMoreTitle: 'View More',
    perPageLimit: 12
  }

  state = {
    limit: this.props.limit,
    minimalDesign: this.props.minimalDesign
  }

  increaseLimit = () =>
    this.setState((prevState) => ({
      limit: prevState.limit + this.props.perPageLimit
    }))

  render() {
    let {
        projects,
        title,
        showLoadMore,
        minimalClassName = '',
        loadMoreTitle
      } = this.props,
      { limit, minimalDesign } = this.state,
      visibleProjects = projects.slice(0, limit || projects.length)

    if (!minimalDesign) {
      minimalClassName = 'ProjectGrid'
    } else {
      minimalClassName = 'ProjectGrid-Minimal'
    }

    return (
      <div className={minimalClassName}>
        {!!minimalDesign && <h2>Work</h2>}
        {title && <h2 className="ProjectGrid--Title">{title}</h2>}
        {!!visibleProjects.length && (
          <div className="ProjectGrid--Grid">
            {visibleProjects.map((project, index) => (
              <ProjectCard key={project.title + index} {...project} />
            ))}
          </div>
        )}
        {showLoadMore && visibleProjects.length < projects.length && (
          <div>
            {!minimalDesign && (
              <div className="ProjectGrid--Loader taCenter">
                <button className="Button" onClick={this.increaseLimit}>
                  {loadMoreTitle}
                </button>
              </div>
            )}
            {minimalDesign && (
              <div className="ProjectGrid--LoadMore Content taCenter">
                <Link to="/work" className={`PostArticle`}>
                  {loadMoreTitle} <ArrowRight size={16} />
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default ProjectGrid
